import React, { FC, useState, useEffect } from 'react';
import {
	Menu,
	ItemType,
	Header as FabricHeader,
} from '@bamboohr/fabric';
import { launchEncoreHeaderSalesForceChat } from '../../../../chat';
import { EmbeddedSvc } from './salesforce-types';
import { useSearchHelpData } from './use-search-help-data';
import { useStyles } from './styles';
import { NoResults } from '../../no-results';

type HelpMenuProps = {
	learningLink: string;
	helpCenterLink: string;
};

export const HelpMenu: FC<React.PropsWithChildren<HelpMenuProps>> = (props) => {
	const classes = useStyles();
	const { learningLink, helpCenterLink } = props;
	const addonsLink =
		'https://www.bamboohr.com/pricing/expansion-addons?utm_campaign=BAMB-CM-help_dropdown-2023EG&utm_medium=expansion&utm_source=product&utm_content=blank&utm_term=blank';
	const updatesLink = 'https://www.bamboohr.com/product-updates/';

	const [open, setOpen] = useState(false);
	const [searchParam, setSearchParam] = useState('');
	const [searchItems, setSearchItems] = useState<Array<ItemType> | undefined>(
		[],
	);
	const [chatOnline, setChatOnline] = useState(false);
	const [salesForceChatLoaded, setSalesForceChatLoaded] = useState(false);

	const subItems: Array<ItemType> = [];
	const endItems: Array<ItemType> = [];

	useSearchHelpData(searchParam, setSearchItems);

	if (chatOnline) {
		subItems.push({
			text: $.__('Chat with Us Now'),
			value: 'chat',
			icon: 'comments-regular',
		});
	}
	subItems.push(
		...[
			{
				icon: 'life-ring-regular',
				text: $.__('Submit a Support Request'),
				value: 'support-request',
			},
			{
				href: helpCenterLink,
				icon: 'circle-question-regular',
				text: $.__('BambooHR Help Center'),
				value: 'help-center',
			},
		],
	);

	if (window.GLOBAL_IS_TRIAL === 'false') {
		endItems.push({
			href: addonsLink,
			icon: 'compass-regular',
			text: 'Explore Our Add-Ons',
			value: 'explore-our-addons',
		});
	}

	endItems.push({
		href: updatesLink,
		icon: 'megaphone-regular',
		text: 'Product Updates',
		value: 'product-updates',
	});

	const items: Array<ItemType> = [
		searchItems?.length
			? {
					items: searchItems,
					text: '',
					type: 'group',
					key: 1,
			}
			: {
					component: () => <NoResults searchText={searchParam} />,
			},
		{
			items: subItems,
			text: '',
			type: 'group',
			key: 2,
			anchor: 'bottom',
		},
		{
			items: [
				{
					href: learningLink,
					icon: 'graduation-cap-regular',
					text: 'BambooHR Learning Courses',
					value: 'learning-courses',
				},
			],
			text: '',
			type: 'group',
			key: 3,
			anchor: 'bottom',
		},
		{
			items: endItems,
			text: '',
			type: 'group',
			key: 4,
			anchor: 'bottom',
		},
	];

	function openChat() {
		if (chatOnline) {
			(
				window as unknown as Window & { embedded_svc: EmbeddedSvc }
			).embedded_svc.showHelpButton();
		}
	}

	useEffect(() => {
		const isChatting = window.sessionStorage.getItem('salesforce-chat-active');
		if (isChatting) {
			launchEncoreHeaderSalesForceChat((data) => {
				console.log("Is chatting", data);
				setChatOnline(data.detail.isAgentAvailable);
				setSalesForceChatLoaded(true);
			});
		}
	}, []);

	return (
		<Menu
			biId='global-header-help-menu'
			isOpen={open}
			items={items}
			onClose={() => setOpen(false)}
			onOpen={() => {
				setOpen(() => {
					if (!salesForceChatLoaded) {
						launchEncoreHeaderSalesForceChat((data) => {
							console.log("Starting", data);
							setChatOnline(data.detail.isAgentAvailable);
							setSalesForceChatLoaded(true);
						});
					}
					return true;
				});
			}}
			onSearch={(value) => setSearchParam(value)}
			onSelect={(item) => {
				if (item.value === 'chat') {
					openChat();
				} else if (item.value === 'support-request') {
					(window as (Window & typeof globalThis) & { showHelpWidget: (amdin: boolean) => void }).showHelpWidget(false);
				}
			}}
			placement={{ side: 'bottom', align: 'end' }}
			renderToggle={(options, buttonProps) =>
				<FabricHeader.Action
					{...buttonProps}
					activeIcon={'circle-question-solid'}
					aria-label="Help"
					focused={open}
					icon={'circle-question-regular'}
					key="help-menu"
					role="button"
				/>
			}
			searchText={searchParam}
			showSearch="always"
			width={9}
		>
			<div className={classes.header}>
				<h5>{$.__('How can we help you?')}</h5>
			</div>
		</Menu>
	);
};
